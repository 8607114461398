.card-wrapper {
  color: inherit;
  height: 100%;
  position: relative;
  text-decoration: none;
}

.card {
  text-decoration: none;
  text-align: var(--text-alignment);
}

.card:not(.ratio) {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card.card--horizontal {
  --text-alignment: left;
  --image-padding: 0rem;
  flex-direction: row;
  align-items: flex-start;
  gap: 1.5rem;
}

.card--horizontal.ratio:before {
  padding-bottom: 0;
}

.card--card.card--horizontal {
  padding: 1.2rem;
}

.card--card.card--horizontal.card--text {
  column-gap: 0;
}

.card--card {
  height: 100%;
}

.card--card,
.card--standard .card__inner {
  position: relative;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  border: var(--border-width) solid rgba(var(--color-foreground), var(--border-opacity));
}

.card--card:after,
.card--standard .card__inner:after {
  content: '';
  position: absolute;
  z-index: -1;
  width: calc(var(--border-width) * 2 + 100%);
  height: calc(var(--border-width) * 2 + 100%);
  top: calc(var(--border-width) * -1);
  left: calc(var(--border-width) * -1);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-horizontal-offset) var(--shadow-vertical-offset) var(--shadow-blur-radius)
    rgba(var(--color-shadow), var(--shadow-opacity));
}

/* Needed for gradient continuity with or without animation, the transform scopes the gradient to its container which happens already when animation are turned on */
.card--card.gradient,
.card__inner.gradient {
  transform: perspective(0);
}

/* Needed for gradient continuity with or without animation so that transparent PNG images come up as we would expect */
.card__inner.color-scheme-1 {
  background: transparent;
}

.card .card__inner .card__media {
  overflow: hidden;
  /* Fix for Safari border bug on hover */
  z-index: 0;
  border-radius: calc(var(--border-radius) - var(--border-width) - var(--image-padding));
}

.card--card .card__inner .card__media {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card--standard.card--text {
  background-color: transparent;
}

.card-information {
  text-align: var(--text-alignment);
}

.card__media,
.card .media {
  bottom: 0;
  position: absolute;
  top: 0;
}

.card .media {
  width: 100%;
}

.card__media {
  margin: var(--image-padding);
  width: calc(100% - 2 * var(--image-padding));
}

.card--standard .card__media {
  margin: var(--image-padding);
}

.card__inner {
  width: 100%;
}

.card--media .card__inner .card__content {
  position: relative;
  padding: calc(var(--image-padding) + 1rem);
  z-index: -1;
}

.card__content {
  display: grid;
  grid-template-rows: minmax(0, 1fr) max-content minmax(0, 1fr);
  padding: 1rem;
  width: 100%;
  flex-grow: 1;
}

.card__content--auto-margins {
  grid-template-rows: minmax(0, auto) max-content minmax(0, auto);
}

.card__information {
  grid-row-start: 2;
  padding: 1.3rem 1rem;
}

.card:not(.ratio) > .card__content {
  grid-template-rows: max-content minmax(0, 1fr) max-content auto;
}

.card-information .card__information-volume-pricing-note {
  margin-top: 0.6rem;
  line-height: calc(0.5 + 0.4 / var(--font-body-scale));
  color: rgba(var(--color-foreground), 0.75);
}

.card__information-volume-pricing-note--button,
.card__information-volume-pricing-note--button.quantity-popover__info-button--icon-with-label {
  position: relative;
  z-index: 1;
  cursor: pointer;
  padding: 0;
  margin: 0;
  text-align: var(--text-alignment);
  min-width: auto;
}

.card__information-volume-pricing-note--button:hover {
  text-decoration: underline;
}

.card__information-volume-pricing-note--button + .global-settings-popup.quantity-popover__info {
  transform: initial;
  top: auto;
  bottom: 4rem;
  max-width: 20rem;
  width: calc(95% + 2rem);
}

.card__information-volume-pricing-note--button + .global-settings-popup.quantity-popover__info span:first-of-type {
  padding-right: 0.3rem;
}

.card__information-volume-pricing-note--button-right + .global-settings-popup.quantity-popover__info {
  right: 0;
  left: auto;
}

.card__information-volume-pricing-note--button-center + .global-settings-popup.quantity-popover__info {
  left: 50%;
  transform: translate(-50%);
}

.card__information-volume-pricing-note--button + .global-settings-popup.quantity-popover__info .quantity__rules {
  text-align: left;
}

@media screen and (min-width: 990px) {
  .grid--6-col-desktop .card__content quick-add-bulk .quantity {
    width: auto;
  }

  .grid--6-col-desktop .card__content quick-add-bulk .quantity__button {
    width: calc(3rem / var(--font-body-scale));
  }

  .grid--6-col-desktop .card__information-volume-pricing-note--button + .global-settings-popup.quantity-popover__info {
    left: 50%;
    transform: translate(-50%);
    width: calc(100% + var(--border-width) + 3.5rem);
  }

  .grid--6-col-desktop
    .card--standard
    .card__information-volume-pricing-note--button
    + .global-settings-popup.quantity-popover__info {
    width: calc(100% + var(--border-width) + 1rem);
  }
}

@media screen and (max-width: 749px) {
  .grid--2-col-tablet-down .card__content quick-add-bulk .quantity__button {
    width: calc(3.5rem / var(--font-body-scale));
  }

  .grid--2-col-tablet-down
    .card--card
    .card__information-volume-pricing-note--button
    + .global-settings-popup.quantity-popover__info,
  .grid--2-col-tablet-down
    .card--standard
    .card__information-volume-pricing-note--button
    + .global-settings-popup.quantity-popover__info {
    left: 50%;
    transform: translate(-50%);
  }

  .grid--2-col-tablet-down
    .card--standard
    .card__information-volume-pricing-note--button
    + .global-settings-popup.quantity-popover__info {
    width: 100%;
  }

  .grid--2-col-tablet-down
    .card--card
    .card__information-volume-pricing-note--button
    + .global-settings-popup.quantity-popover__info {
    width: calc(100% + var(--border-width) + 4rem);
  }

  .grid--2-col-tablet-down .card__content quick-add-bulk .quantity {
    width: auto;
  }
}

.card-information quantity-popover volume-pricing {
  margin-top: 0;
}

@media screen and (max-width: 989px) {
  .card-information quantity-popover .quantity__rules ~ volume-pricing {
    margin-top: 0;
  }

  .card-information quantity-popover volume-pricing {
    margin-top: 4.2rem;
  }
}

@media screen and (min-width: 750px) {
  .card__information {
    padding-bottom: 1.7rem;
    padding-top: 1.7rem;
  }
}

.card__badge {
  align-self: flex-end;
  grid-row-start: 3;
  justify-self: flex-start;
}

.card__badge.top {
  align-self: flex-start;
  grid-row-start: 1;
}

.card__badge.right {
  justify-self: flex-end;
}

.card:not(.card--horizontal) > .card__content > .card__badge {
  margin: 1.3rem;
}

.card__media .media img {
  height: 100%;
  object-fit: cover;
  object-position: center center;
  width: 100%;
}

.card__inner:not(.ratio) > .card__content {
  height: 100%;
}

.card__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.card__heading:last-child {
  margin-bottom: 0;
}

.card--horizontal .card__heading,
.card--horizontal .price__container .price-item,
.card--horizontal__quick-add {
  font-size: calc(var(--font-heading-scale) * 1.2rem);
}

.card--horizontal
  .card-information
  > *:not(.visually-hidden:first-child)
  + *:not(.rating):not(.card__information-volume-pricing-note) {
  margin-top: 0;
}

.card--horizontal__quick-add:before {
  box-shadow: none;
}

@media only screen and (min-width: 750px) {
  .card--horizontal .card__heading,
  .card--horizontal .price__container .price-item,
  .card--horizontal__quick-add {
    font-size: calc(var(--font-heading-scale) * 1.3rem);
  }
}

.card--card.card--media > .card__content {
  margin-top: calc(0rem - var(--image-padding));
}

.card--standard.card--text a::after,
.card--card .card__heading a::after {
  bottom: calc(var(--border-width) * -1);
  left: calc(var(--border-width) * -1);
  right: calc(var(--border-width) * -1);
  top: calc(var(--border-width) * -1);
}

.card__heading a::after {
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.card__heading a:after {
  outline-offset: 0.3rem;
}

.card__heading a:focus:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
}

.card__heading a:focus-visible:after {
  box-shadow: 0 0 0 0.3rem rgb(var(--color-background)), 0 0 0.5rem 0.4rem rgba(var(--color-foreground), 0.3);
  outline: 0.2rem solid rgba(var(--color-foreground), 0.5);
}

.card__heading a:focus:not(:focus-visible):after {
  box-shadow: none;
  outline: 0;
}

.card__heading a:focus {
  box-shadow: none;
  outline: 0;
}

@media screen and (min-width: 990px) {
  .card .media.media--hover-effect > img:only-child,
  .card-wrapper .media.media--hover-effect > img:only-child {
    transition: transform var(--duration-long) ease, opacity var(--duration-long) ease;
  }

  .card:hover .media.media--hover-effect > img:first-child:only-child,
  .card-wrapper:hover .media.media--hover-effect > img:first-child:only-child {
    transform: scale(1.03);
  }

  .card-wrapper:hover .media.media--hover-effect > img:first-child:not(:only-child) {
    opacity: 0;
  }

  .card-wrapper:hover .media.media--hover-effect > img + img {
    opacity: 1;
    transition: transform var(--duration-long) ease;
    transform: scale(1.03);
  }

  .underline-links-hover:hover a {
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }
}

.card--standard.card--media .card__inner .card__information,
.card--standard.card--text:not(.card--horizontal) > .card__content .card__heading:not(.card__heading--placeholder),
.card--standard:not(.card--horizontal) > .card__content .card__badge,
.card--standard.card--text.article-card > .card__content .card__information,
.card--standard > .card__content .card__caption {
  display: none;
}

.card--standard:not(.card--horizontal) .placeholder-svg {
  width: 100%;
}

.card--standard > .card__content {
  padding: 0;
}

.card--standard > .card__content .card__information {
  padding-left: 0;
  padding-right: 0;
}

.card--card.card--media .card__inner .card__information,
.card--card.card--text .card__inner,
.card--card.card--media > .card__content .card__badge {
  display: none;
}

.card--horizontal .card__badge,
.card--horizontal.card--text .card__inner {
  display: none;
}

.card--extend-height {
  height: 100%;
}

.card--extend-height.card--standard.card--text,
.card--extend-height.card--media {
  display: flex;
  flex-direction: column;
}

.card--extend-height.card--standard.card--text .card__inner,
.card--extend-height.card--media .card__inner {
  flex-grow: 1;
}

.card .icon-wrap {
  margin-left: 0.8rem;
  white-space: nowrap;
  transition: transform var(--duration-short) ease;
  overflow: hidden;
}

.card-information > * + * {
  margin-top: 0.5rem;
}

.card-information {
  width: 100%;
}

.card-information > * {
  line-height: calc(1 + 0.4 / var(--font-body-scale));
  color: rgb(var(--color-foreground));
}

.card-information > .price {
  color: rgb(var(--color-foreground));
}

.card--horizontal .card-information > .price {
  color: rgba(var(--color-foreground), 0.75);
}

.card-information > .rating {
  margin-top: 0.4rem;
}

/* Specificity needed due to the changes below */
.card-information
  > *:not(.visually-hidden:first-child)
  + quantity-popover:not(.rating):not(.card__information-volume-pricing-note),
.card-information .card__information-volume-pricing-note.card__information-volume-pricing-note--button {
  margin-top: 0;
}

.card-information > *:not(.visually-hidden:first-child) + *:not(.rating):not(.card__information-volume-pricing-note) {
  margin-top: 0.6rem;
}

.card-information .caption {
  letter-spacing: 0.07rem;
}

.card-article-info {
  margin-top: 1rem;
}

/* Card Shapes */

.card--shape .card__content {
  padding-top: 0;
}

.card--shape.card--standard:not(.card--text) .card__inner {
  border: 0;
  /* Border is not currently compatible with image shapes for standard cards. */
  background-color: transparent;
  filter: drop-shadow(
    var(--shadow-horizontal-offset) var(--shadow-vertical-offset) var(--shadow-blur-radius)
      rgba(var(--color-shadow), var(--shadow-opacity))
  );
}

.card--shape.card--standard:not(.card--text) .card__inner:after {
  display: none;
}

.grid__item:nth-child(2n) .shape--blob {
  clip-path: polygon(var(--shape--blob-2));
}

.grid__item:nth-child(3n) .shape--blob {
  clip-path: polygon(var(--shape--blob-3));
}

.grid__item:nth-child(4n) .shape--blob {
  clip-path: polygon(var(--shape--blob-4));
}

.grid__item:nth-child(5n) .shape--blob {
  clip-path: polygon(var(--shape--blob-5));
}

.grid__item:nth-child(7n) .shape--blob {
  clip-path: polygon(var(--shape--blob-6));
}

.grid__item:nth-child(8n) .shape--blob {
  clip-path: polygon(var(--shape--blob-1));
}

/* Card Shape Hover Rules */

@media (prefers-reduced-motion: no-preference) {
  .product-card-wrapper .shape--round {
    transition: clip-path var(--duration-long) ease;
  }

  .product-card-wrapper:hover .shape--round {
    clip-path: ellipse(47% 47% at 50% 50%);
  }

  .product-card-wrapper .shape--blob {
    transition: clip-path var(--duration-long) ease-in-out;
  }

  .product-card-wrapper:hover .shape--blob {
    clip-path: polygon(var(--shape--blob-5));
  }

  .grid__item:nth-child(2n) .product-card-wrapper:hover .shape--blob {
    clip-path: polygon(var(--shape--blob-6));
  }

  .grid__item:nth-child(3n) .product-card-wrapper:hover .shape--blob {
    clip-path: polygon(var(--shape--blob-1));
  }

  .grid__item:nth-child(4n) .product-card-wrapper:hover .shape--blob {
    clip-path: polygon(var(--shape--blob-2));
  }

  .grid__item:nth-child(5n) .product-card-wrapper:hover .shape--blob {
    clip-path: polygon(var(--shape--blob-3));
  }

  .grid__item:nth-child(7n) .product-card-wrapper:hover .shape--blob {
    clip-path: polygon(var(--shape--blob-4));
  }

  .grid__item:nth-child(8n) .product-card-wrapper:hover .shape--blob {
    clip-path: polygon(var(--shape--blob-5));
  }
}

.product-card-wrapper .card .card__inner {
  border: 0.05rem solid rgb(var(--color-shadow));
  border-radius: 1rem;
  overflow: hidden;
  background: rgb(var(--color-background));
}

.product-card-wrapper .card .card__inner::before {
  padding-bottom: 100%;
}


.product-card-wrapper .card .card__inner .card__media img {
  padding: 1.4rem;
  object-fit: contain;
  object-position: center center;
}

.product-card-wrapper .card  .card__heading {
  font-family: var(--font-family);
  font-size: 1.3rem;
  line-height: 1.69rem;
  font-weight: 400;
  color: rgb(var(--color-foreground));
  text-align: center;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}

.product-card-wrapper .card .price {
  font-family: var(--font-family);
  font-size: 1.3rem;
  line-height: 1.82rem;
  font-weight: 700;
  color: rgb(var(--color-button));
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-card-wrapper:hover .card  .card__heading {
  color: rgb(var(--color-button));
}


.product-card-wrapper .card > .card__content .card__information {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-card-wrapper .card > .card__content .card__information .product-sku {
  font-family: var(--font-family);
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.32rem;
  color: rgba(var(--color-foreground), 0.7);
  background: rgba(var(--color-foreground), 0.05);
  border-radius: 0.6rem;
  padding: 0.45rem 1rem 0.35rem;
  margin-bottom: 1.1rem;
}

.product-card-wrapper .card > .card__content .card__information {
  padding: 1.5rem 1rem;
}

.product-card-wrapper .card > .card__inner .quick-add {
  position: absolute;
  top: calc(50% + 28px);
  left: 3.8rem;
  right: 3.8rem;
  margin: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  transform: translateY(2rem);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.quick-add__submit {
  font-family: var(--font-family);
  font-size: 1.2rem;
  line-height: 1.44rem;
  font-weight: 750;
  border-radius: 0.6rem;
  background: rgba(var(--color-button-text));
  color: rgba(var(--color-button));
}

.quick-add__submit:hover {
  background: rgba(var(--color-foreground));
}

.quick-add__submit::before,
.quick-add__submit::after {
  display: none;
}

.product-card-wrapper .card .card__heading a::after {
  display: none;
}

.product-card-wrapper .card:hover > .card__inner .quick-add {
  opacity: 1;
  transform: translateY(-50%);
  visibility: visible;
  pointer-events: auto;
}

.product-card-wrapper .card:hover > .card__inner .card__media img {
  opacity: 0.3;
}

.recommended-accessories-main .product-card-wrapper .card:hover>.card__inner .card__media img,
.recent-view-slider .product-card-wrapper .card:hover>.card__inner .card__media img {
  opacity: 1;
}

@media screen and (max-width: 1023px) {
  .product-card-wrapper .card>.card__content .card__information {
    padding-bottom: 1.1rem;
  }

  .product-card-wrapper .card .card__heading a::after {
    display: block;
  } 

  .product-card-wrapper .card > .card__inner .quick-add {
    display: none;
  }
  .card .media .qview-button-wrapper {
    display: none;
  }
}

/* Quick View */
.card .media .qview-button-wrapper {
  left: 3.8rem;
  right: 3.8rem;
  width: auto;
  height: auto;
  top: calc(50% - 2.8rem);
  transform: translateY(5rem);
  opacity: 0;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}
.card:hover .qview-button-wrapper {
  visibility: visible;
  opacity: 1;
  transform: translateY(-50%);
}

.qview-button-wrapper .qview-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 1.9rem 0.8rem !important;
  cursor: pointer;
  font: inherit;
  text-transform: uppercase;
  font-style: italic;
  font-size: 1.2rem;
  line-height: 1.44rem;
  font-weight: 750;
  border-radius: 0.6rem !important;
  text-decoration: none;
  transition: 0.3s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(var(--color-foreground)) !important;
  opacity: 1;
  width: 100%  !important;
  text-transform: uppercase !important;
}
.qview-button .qview-button-text{
  font-size: 1.2rem;
}
.qview-button-wrapper .qview-button:hover{
  background-color: rgba(var(--color-button)) !important;
}

.qview-button-wrapper.qview-button-wrapper--has-overlay{
  background: none;
}

/* Quick Modal */

@media screen and (max-width: 1280px) {
  .qikify-quickview-app .qview-lightbox .v--modal{
    max-width: 980px;
  }
}
.qikify-quickview-app .qview-lightbox-wrapper.qlightbox{
  border-radius: 2rem;
}
.qview-gallery .qview-thumbnails{
  width: 8rem;
  margin-right: 1rem;
}
.qview-thumbnails .qview-thumbnail{
  margin-bottom: 1.1rem;
  aspect-ratio: 1;
  border-radius: 1.5rem;
  border: .1rem solid #E6E9EC;
}
.qview-thumbnails .qview-thumbnail.qview-thumbnail--active{
  border-color: rgb(var(--color-button));
}

.qview-thumbnails .qview-thumbnail-img{
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

.qview-slider .qview-slider__next, .qview-slider .qview-slider__prev{
  background-color: rgb(var(--color-background));
  position: relative;
  border-radius: 1rem;
  border: 0.1rem solid #E6E9EC !important;
  border-radius: 1rem !important;
  background-color: rgb(var(--color-background)) !important;
  opacity: 1;
}
.qview-slider .qview-slider__next:hover, .qview-slider .qview-slider__prev:hover{
  background: rgb(var(--color-foreground)) !important;
}


.qview-slider .qview-slider__next svg, .qview-slider .qview-slider__prev svg{
  display: none;
}

.qview-slider .qview-slider__prev::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none"><path d="M11 6H1M1 6L6 1M1 6L6 11" stroke="%23041E42" stroke-width="1.5" stroke-linejoin="bevel"/></svg>');
}
.qview-slider .qview-slider__prev:hover::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none"><path d="M11 6H1M1 6L6 1M1 6L6 11" stroke="%23ffffff" stroke-width="1.5" stroke-linejoin="bevel"/></svg>');
}
.qview-slider .qview-slider__next::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none"><path d="M0 6H10M10 6L5 1M10 6L5 11" stroke="%23041E42" stroke-width="1.5" stroke-linejoin="bevel"/></svg>');
}
.qview-slider .qview-slider__next:hover::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none"><path d="M0 6H10M10 6L5 1M10 6L5 11" stroke="%23ffffff" stroke-width="1.5" stroke-linejoin="bevel"/></svg>');
}

.qview-slider .qview-slider__next:after, .qview-slider .qview-slider__prev:after{
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.4rem;
  height: 1.4rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


.qview-main {
  height: 100%;
}

.qview-main .qview-main-content{
  padding: 2rem;
  border-radius: 2rem;
  border: .1rem solid rgba(var(--color-shadow), 1);
}

.qview-main-content .qview-block--title{
  margin-bottom: 1.2rem;
}

.qview-main-content .qview-title a{
  font-size: 2.4rem !important;
  line-height: 100% !important;
  font-weight: 500 !important;
  text-transform: uppercase;
  letter-spacing: normal !important;
}

.qview-main-content .qview-block--price{
  padding-bottom: 1.8rem;
  border-bottom: .1rem solid rgba(var(--color-shadow), 1);
  margin-bottom: 2rem;
}

.qview-main-content .qview-price{
  font-size: 2rem ;
  line-height: 2rem ;
  font-weight: 700 ;
  color: rgba(var(--color-button)) ;
  letter-spacing: normal;
}

.qview-price .qview-price__current{
  font-weight: 700;
}

.qview-main-content .qview-block--reviews:has(.jdgm-preview-badge:empty){
  margin: 0;
}
.qview-main-content .qview-sku{
  font-size: 1.3rem;
  line-height: 1.56;
  text-transform: uppercase;
  padding: 0 1rem;
  border-radius: 0.6rem;
  color: rgba(var(--color-foreground), 1);
  background: rgba(var(--color-foreground), .05);
  width: fit-content;
}

.qview-sku .qview-atts__title{
  font-weight: 500;
}
.qview-sku .qview-atts__value {
  color: rgba(var(--color-foreground), 1);

}
.qview-main-content .qview-form{
  padding: 0;
  margin-bottom: 2rem;
}

.qview-main-content  .qview-field__title{
  display: none;
}

.qview-main-content .qview-available{
  display: inline;
}

.qview-variants{
  margin-bottom: 0;
}

.qview-main-content .qview-input-quantity{
  display: inline-block;
  margin-bottom: 0;
}

.qview-main-content .qview-buttons{
  display: inline-block;
  width: calc(100% - 16rem);
  float: right;
  padding: 0;
  margin: 0;
}

.qview-main-content .qview-input-group{
  border: .05rem solid rgb(var(--color-foreground));
  border-radius: 1rem;
  height: 5rem;
  overflow: hidden;
  padding: 0rem 2.4rem;
}
.qview-main-content .qview-input-group .qview-input{
  font-size: 1.8rem;
  font-weight: 700;
  height: 5rem;
  border: 0 !important;
  padding: 0 0.7rem;
  max-width: 7.3rem;
  color: rgba(var(--color-foreground));
}
.qview-main-content .qview-input-group>span{
  border: 0;
  padding: 0;
  background: none;
  position: relative;
}

.qview-main-content .qview-input-group>span svg{
  opacity: 0;
}

.qview-main-content .qview-input-group .qview-input-plus::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 0L6 12" stroke="%23041E42" stroke-width="2.5"/><path d="M0 6L12 6" stroke="%23041E42" stroke-width="2.5"/></svg>');  
}

.qview-main-content .qview-input-group .qview-input-plus:hover::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><path d="M6 0L6 12" stroke="%23FF6720" stroke-width="2.5"/><path d="M0 6L12 6" stroke="%23FF6720" stroke-width="2.5"/></svg>');  
}

.qview-main-content .qview-input-group .qview-input-minus::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4" fill="none"><path d="M0 2L12 2" stroke="%23041E42" stroke-opacity="1" stroke-width="2.5"/></svg>');
}

.qview-main-content .qview-input-group .qview-input-minus:hover::after{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4" fill="none"><path d="M0 2L12 2" stroke="%23FF6720" stroke-opacity="1" stroke-width="2.5"/></svg>');
}

.qview-main-content .qview-input-group .qview-input-plus::after , .qview-main-content .qview-input-group .qview-input-minus::after{
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 1.2rem;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.qview-main-content .qview-btn-wrap{
  width: 100%;
}

.qview-main-content .qview-buttons .qview-btn{
  font-size: 1.4rem !important;
  line-height: 1.68rem !important;
  font-weight: 700;
  letter-spacing: 0;
  text-decoration: none;
  font-style: italic;
  text-transform: uppercase !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 1rem !important;
  padding: 1.7rem 2.6rem !important;
  background-color: rgba(var(--color-button), var(--alpha-button-background)) !important;
  margin-bottom: 0 !important;
  width: 100% !important;
}

.qview-main-content .qview-buttons .qview-btn:hover{
  background-color: rgb(var(--color-foreground)) !important;
}

.qview-main-content .qview-block--info{
  margin-bottom: 0;
}

.qview-variants .qview-fields{ 
  width: 100%;
  margin-bottom: 2rem;
}
.qview-variants .qview-option{
  width: 100%;
}
.qview-variants .qview-option:last-child{
  margin-bottom: 0;
}
.qview-variants .qview-select{
  width: 100%;
}
.qview-variants .qview-option__title{
  font-size: 1.4rem !important;
  line-height: 1.96rem;
  font-weight: 500 !important;
  margin-bottom: .9rem !important;
  color: rgb(var(--color-foreground)) !important;
  text-transform: none;
}
.qview-variants .qview-select select{
  font-size: 1.4rem !important;
  line-height: 1.9rem;
  color: rgb(var(--color-foreground), .5) !important;
  font-family: var(--font-family) !important;
  width: 100% !important;
  height: 5rem !important;
  padding: 1rem 3.5rem 1rem 2rem !important;
  border: .05rem solid rgb(var(--color-shadow)) !important;
  border-radius: 1rem !important;
  background: rgb(var(--color-background));
}
.qview-variants .qview-select:after{
  border: 0;
  top: 1.7rem;
  right: 1.5rem;
  width: 2rem;
  height: 2rem;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><circle cx="10" cy="10" r="10" fill="%23EFEFEF"/><path d="M7 9L10 11L13 9" stroke="%23041E42"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}